import { useTheme } from "@mui/material/styles";
import React from "react";

import Refferal from "../../../components/updated/authentication/signup/Refferal";
import { useLocation } from "react-router-dom";
import johnLight from "../../../assets/updated/auth/John Light mode.svg";
import johnDark from "../../../assets/updated/auth/John Dark Mode.svg";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  Container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme?.palette?.background.default,
    color: theme?.palette?.text?.primary,
    marginTop: "50px",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  orderBy: {
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
}));

const RefferalPage = () => {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  let referralCode = location.state?.referralCode;
  return (
    <div className={classes.Container}>
      {referralCode && referralCode === "zsuitepay" && (
        <div className={classes.orderBy} style={{ flex: 1 }}>
          <img
            src={theme.palette.mode === "dark" ? johnDark : johnLight}
            alt=""
            width={"95%"}
          />
        </div>
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Refferal />
      </div>
    </div>
  );
};

export default RefferalPage;
