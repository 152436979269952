import React, { useEffect, useState } from "react";
import "./IndexBlog.css";
import Footer from "../Footer/Footer";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import blogicon from "../../assets/blogicon.png";
import { getIndexxMediumBlogs } from "../../services/api";
import CardGrid from "../CardComponent/CardGrid";

const IndexBlog = () => {
  const [itemRows1, setItemRows1] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchBlogs = async () => {
    try {
      const res = await getIndexxMediumBlogs();
      setItemRows1(res);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <div style={loading ? { paddingTop: "180px" } : { paddingTop: "60px" }}>
        <div
          className="blog_container container"
          style={{ paddingBottom: 200 }}
        >
          <div className="margin-t-2x blog_container_header">
            <div
              className=""
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <div className="">
                <img src={blogicon} alt="blogicon" />
              </div>
              <div className="">
                <p className="font_60x" style={{ textAlign: "left" }}>
                  Blog
                </p>
              </div>
            </div>
          </div>
          <div className="margin-t-2x blog_container_main">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <CardGrid posts={itemRows1} showEllipses={true} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IndexBlog;
