import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import LoginComponent from "../../../components/updated/authentication/loginEmail";
import { useLocation, useNavigate } from "react-router-dom";
import johnLight from "../../../assets/updated/auth/John Light mode.svg";
import johnDark from "../../../assets/updated/auth/John Dark Mode.svg";
import {
  getUserShortToken,
  decodeJWT,
  baseNewCEXURL,
  baseLottoUrl,
  baseAcademyUrl,
  baseIndexxShopUrl,
} from "../../../services/api";
import RedirectionPopup from "../../../components/updated/shared/RedirectionPopup";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme: any) => ({
  Container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme?.palette?.background.default,
    color: theme?.palette?.text?.primary,
    marginTop: "50px",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  orderBy: {
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
}));

const handleNavigation = async (
  url: any,
  setLoading: (loading: boolean) => void
) => {
  setLoading(true);
  const isAuthenticated = localStorage.getItem("access_token");

  const email = localStorage.getItem("email");
  let shortToken;
  if (email) {
    shortToken = await getUserShortToken(email);
  } else if (isAuthenticated) {
    let decodedValue: any = await decodeJWT(isAuthenticated);
    shortToken = await getUserShortToken(decodedValue?.email);
  }

  if (isAuthenticated) {
    const urlObj = new URL(url);
    urlObj.searchParams.set("signInToken", shortToken?.data);
    const urlWithToken = urlObj.toString();
    window.location.href = urlWithToken;
  } else {
    window.location.href = url;
  }
};

const Login = () => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const originalRedirectLink = searchParams.get("redirectWebsiteLink");
  const referralCode = searchParams.get("referralCode");

  const [loading, setLoading] = useState(false);

  let redirectWebsiteLink: any;
  if (originalRedirectLink === "exchange") {
    redirectWebsiteLink = baseNewCEXURL;
  } else if (originalRedirectLink === "lotto") {
    redirectWebsiteLink = baseLottoUrl;
  } else if (originalRedirectLink === "academy") {
    redirectWebsiteLink = baseAcademyUrl;
  }  else if (originalRedirectLink === "shop") {
    redirectWebsiteLink = baseIndexxShopUrl;
  }

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = localStorage.getItem("access_token");
      if (isAuthenticated) {
        await handleNavigation(redirectWebsiteLink || "/", setLoading);
      }
    };

    const action = searchParams.get("action");
    if (action === "Logout") {
      localStorage.removeItem("user"); // Remove one item
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("email");
      localStorage.clear(); // Clear all local storage
      window.location.href = "/auth/login";
    } else {
      checkAuthentication();
    }
  }, [redirectWebsiteLink]);

  return (
    <div className={classes.Container}>
      {loading && (
        <RedirectionPopup
          name={originalRedirectLink}
          onClose={() => setLoading(false)}
        />
      )}
      {referralCode && referralCode === "zsuitepay" && (
        <div className={classes.orderBy} style={{ flex: 1 }}>
          <img
            src={theme.palette.mode === "dark" ? johnDark : johnLight}
            alt=""
            width={"95%"}
          />
        </div>
      )}

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoginComponent
          redirectWebsiteLink={redirectWebsiteLink}
          referral={referralCode}
        />
      </div>
    </div>
  );
};

export default Login;
