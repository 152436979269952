import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from "../assets/backToCommunity/back_to_community_logo.svg";
import pkb1 from "../assets/backToCommunity/pkb-1.png";
import pkb2 from "../assets/backToCommunity/pkb-2.png";
import pkb3 from "../assets/backToCommunity/pkb-3.png";
import pkb4 from "../assets/backToCommunity/pkb-4.png";
import pkb5 from "../assets/backToCommunity/pkb-5.png";
import pkb6 from "../assets/backToCommunity/pkb-6.png";
import pkb7 from "../assets/backToCommunity/pkb-7.png";
import pkb8 from "../assets/backToCommunity/pkb-8.png";
import pkb9 from "../assets/backToCommunity/pkb-9.png";

import idd1 from "../assets/backToCommunity/idd1.png";
import idd2 from "../assets/backToCommunity/idd2.png";
import idd3 from "../assets/backToCommunity/idd3.png";
import idd4 from "../assets/backToCommunity/idd4.png";
import idd5 from "../assets/backToCommunity/idd5.png";
import idd6 from "../assets/backToCommunity/idd6.png";

import cryptoSeminar from "../assets/backToCommunity/cryptoSeminar.png";
import indexxAdvocacy from "../assets/backToCommunity/indexxAdvocacy.png";
import safeFamilyJustice from "../assets/backToCommunity/safeFamilyJustice.png";
import safeFamilyJusticeWhite from "../assets/backToCommunity/SFJ white.png";
import { ArrowNextIcon } from "../assets/icons/ArrowNext";
import { ArrowPrevIcon } from "../assets/icons/ArrowPrev";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2rem",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  heading: {
    fontSize: "32px",
    fontWeight: "700",
    color: `${theme?.palette?.text?.primary} !important`,
    textAlign: "center",
    marginBottom: "2rem",
  },
  subheading: {
    fontSize: "20px",
    fontWeight: "600",
    color: `${theme?.palette?.text?.primary} !important`,
    textAlign: "center",
    marginBottom: "1rem",
  },
  paragraph: {
    fontSize: "18px",
    color: `${theme?.palette?.text?.secondary} !important`,
    textAlign: "center",
    maxWidth: "800px",
    margin: "0 auto 200px auto",
    marginBottom: "12rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  imageCenter: {
    display: "block",
    margin: "58px auto",
    marginBottom: "2rem",
    maxWidth: "100%",
  },

  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1080px",
    gap: "20px",
    margin: "5rem auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  flexItem: {
    flex: 1,
    padding: "1rem",
    textAlign: "left",
  },
  flexImage: {
    maxWidth: "100%",
    height: "auto",
  },
  swiperContainer: {
    maxWidth: "1440px",
    width: "80%",
    position: "relative", // Set position relative to enable absolute positioning of arrows
    margin: "2rem auto",
  },
  slickArrow: {
    position: "absolute",
    top: "50%", // Center the arrows vertically
    transform: "translateY(-50%)", // Adjust vertical alignment
    backgroundColor: "none",
    height: "50px",
    width: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    opacity: 1,
    zIndex: 1,
    cursor: "pointer",
  },
  slickPrev: {
    left: "-10px", // Position to the left of the slider
    [theme.breakpoints.down("md")]: {
      left: "-40px",
    },
  },
  slickNext: {
    right: "-10px", // Position to the right of the slider
    [theme.breakpoints.down("md")]: {
      right: "-40px",
    },
  },
  slickArrowHover: {
    "&:hover": {
      color:
        theme.palette.mode === "dark" ? "white !important" : "black !important",
      opacity: 1,
    },
  },
  slickArrowIcon: {
    color: "grey",
    fontSize: "30px",
    "&:hover": {
      color:
        theme.palette.mode === "dark" ? "white !important" : "black !important",
      opacity: 1,
    },
  },
}));

const BackToCommunity = () => {
  const classes = useStyles();
  const theme = useTheme();
  const videos = [
    "https://www.youtube.com/embed/V4isDMMW6Q0?si=etFnUq0dC1_lxmWt",
    "https://www.youtube.com/embed/V4isDMMW6Q0?si=etFnUq0dC1_lxmWt",
  ];
  const iframesRef = useRef([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleSlideChange = () => {
    iframesRef.current.forEach((iframe) => {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          "*"
        );
      }
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine arrow color based on theme

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`${classes.slickArrow} ${classes.slickPrev} ${classes.slickArrowHover}`}
      >
        <span className={classes.slickArrowIcon}>
          {" "}
          <ArrowPrevIcon width={28} height={28} />
        </span>
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`${classes.slickArrow} ${classes.slickNext} ${classes.slickArrowHover}`}
      >
        <span className={classes.slickArrowIcon}>
          {" "}
          <ArrowNextIcon width={28} height={28} />
        </span>
      </div>
    );
  };
  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    beforeChange: handleSlideChange, // Pause videos on slide change
    nextArrow: <SampleNextArrow to="next" />,
    prevArrow: <SamplePrevArrow to="prev" />,
  };

  // Custom arrow components

  return (
    <div className={classes.container}>
      {/* Back to Community Section */}
      <div style={{ margin: "100px auto 20px auto" }}></div>
      <img src={logo} alt="Back to Community" className={classes.imageCenter} />
      <h2 className={classes.heading}>Back to Community</h2>
      <p className={classes.paragraph}>
        Our Indexx Family is not just a company, but a community dedicated to
        giving back. For every purchase made on Indexx.ai's platform, 5% of the
        cost goes directly to the affiliate charities, clubs, and personalities.
      </p>

      {/* Sponsorship Slider */}
      <div className={classes.swiperContainer}>
        <Slider {...settings}>
          {videos.map((videoSrc, index) => (
            <div key={index}>
              <iframe
                style={{ display: "inline" }}
                width="90%" // Set width to 100% to ensure responsiveness
                height={windowWidth > 768 ? "500" : "315"} // Adjust height based on screen size
                src={videoSrc}
                title={`YouTube video ${index}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ref={(el) => (iframesRef.current[index] = el)}
              ></iframe>
            </div>
          ))}
        </Slider>
        <div style={{ marginTop: "40px" }}></div>
        <h2 className={classes.heading}>Sponsorship</h2>
        <p className={classes.paragraph}>
          Indexx is proud to sponsor and support young pickleball talents,
          helping them hone their skills and reach new heights. Through this
          initiative, we aim to nurture the next generation of athletes,
          providing resources and opportunities for growth both on and off the
          court. Together, we’re shaping future champions.
        </p>
      </div>

      {/* Indigo Dagnall Slider */}
      <div className={classes.swiperContainer}>
        <Slider {...settings}>
          <div>
            <img
              src={idd1}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
          <div>
            <img
              src={idd2}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
          <div>
            <img
              src={idd3}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>{" "}
          <div>
            <img
              src={idd4}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>{" "}
          <div>
            <img
              src={idd5}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>{" "}
          <div>
            <img
              src={idd6}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
        </Slider>
        <h2 className={classes.heading}>Indigo Dagnall</h2>
        <h4 className={classes.subheading}>Pickle Ball Champ</h4>
        <p className={classes.paragraph}>
          We're excited to support young athletes as the official sponsor of the
          Youth Pickleball Championship League. At Indexx, we believe in
          empowering the next generation both on and off the court. Join us in
          celebrating youth sports and future champions!
        </p>
      </div>

      {/* PickleBall Community Section */}
      <div className={classes.swiperContainer}>
        <Slider {...settings}>
          <div>
            <img
              src={pkb1}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
          <div>
            <img
              src={pkb2}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>

          <div>
            <img
              src={pkb3}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
          <div>
            <img
              src={pkb4}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>

          <div>
            <img
              src={pkb5}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
          <div>
            <img
              src={pkb6}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>

          <div>
            <img
              src={pkb7}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
          <div>
            <img
              src={pkb8}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>

          <div>
            <img
              src={pkb9}
              alt="Pickle Ball Champ"
              className={classes.imageCenter}
            />
          </div>
        </Slider>

        <h2 className={classes.heading}>PickleBall Community</h2>
        <p className={classes.paragraph}>
          As part of our commitment to making a positive impact, Indexx proudly
          sponsors the Pickleball Community. Through this sponsorship, we aim to
          support healthy, active lifestyles and give back to the communities
          that inspire us every day!
        </p>
      </div>
      <div className={classes.swiperContainer} style={{ marginTop: "200px" }}>
        <img
          src={cryptoSeminar}
          alt="Pickle Ball Champ"
          className={classes.imageCenter}
        />

        <h2 className={classes.heading}>Crypto Seminar</h2>
        <p className={classes.paragraph}>
          Join the Indexx Crypto Seminar, where we're empowering communities
          with the knowledge of cryptocurrency and investing! This free event is
          our way of giving back, offering insights, tips, and hands-on guidance
          for anyone interested in the world of crypto. Learn, grow, and build
          your financial future with Indexx
        </p>
      </div>

      <div style={{ margin: "250px" }}></div>
      {/* Flex Container Section */}

      <div>
        <div>
          <iframe
            style={{ display: "inline" }}
            width="80%" // Set width to 100% to ensure responsiveness
            height={windowWidth > 768 ? "500" : "315"} // Adjust height based on screen size
            src={
              "https://www.youtube.com/embed/LP3QkU5fzrE?si=ea9ufVIMlDjbsZob"
            }
            title={`YouTube video`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            ref={(el) => (iframesRef.current[0] = el)}
          ></iframe>
        </div>
        <div className={classes.flexContainer}>
          <div style={{ width: "fit-content" }}>
            <img
              src={
                theme.palette.mode === "dark"
                  ? safeFamilyJusticeWhite
                  : safeFamilyJustice
              }
              style={{ maxWidth: "400px", width: "100%" }}
              alt="Safe Family"
              className={classes.flexImage}
            />
          </div>
          <div className={classes.flexItem}>
            <p className={classes.paragraph} style={{ textAlign: "left" }}>
              Indexx is dedicated to giving back by proudly supporting the Safe
              Family Justice Centers Charity. Through this partnership, we help
              provide crucial resources and support to families in need,
              fostering safety and justice in our communities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackToCommunity;
