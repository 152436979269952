import React from "react";
import { Link } from "react-router-dom";
import nftCoin from "../../../assets/meme/nft.png";

import soRekt from "../../../assets/meme/sorekt.png";

import skullpunks from "../../../assets/meme/skullpunks.png";
import mello from "../../../assets/meme/mello.png";
// import nftImage from "../../../assets/meme/nft-img.png";
import nftImage from "../../../assets/meme/NFT for gallery.png";

import zombie from "../../../assets/meme/zombie.png";
import "./style.css"; // import your CSS file for styling
import IconicHeader from "./IconicHeader";
import Footer from "../../Footer/Footer";

const NFTInner = () => {
  return (
    <div>
      <IconicHeader type="nft" />
      <section
        style={{
          maxWidth: "650px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          margin: "100px auto 0px auto",
          gap: "15px",
        }}
      >
        <div>
          <img src={nftCoin} height="100px" />
        </div>
        <p style={{ fontSize: "50px", fontWeight: "bold" }}>NFT Projects</p>
      </section>
      <section className="project-section">
        {/* <h1 className="main-heading">Projects</h1> */}
        <div className="project-container">
          {/* Project Cards */}
          <div className="project-card">
            <a href="https://whoisbitcoinsatoshi.wtf/nft" target="_blank">
              <img src={nftImage} alt="Project 1" />
            </a>

            <div>
              <h2>Bitcoin Satoshi NFT</h2>
              <p>
                NFT based on the myster of satoshi nakamoto and the emergence of
                bitcoin as the king of crypto generated using top of the line AI
                models
              </p>
            </div>
            <a
              href="https://whoisbitcoinsatoshi.wtf/nft"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="/sorekt-coming-soon">
              <img src={soRekt} alt="Project 1" />
            </a>

            <div>
              <h2>SoRekt</h2>
              <p>
                SoRekt NFTs represent everything that went wrong with the crypto
                ecosystem from scammers to criminals to hacks.
              </p>
            </div>
            <a
              href="/sorekt-coming-soon"
              className="learn-more-button"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>

          <div className="project-card">
            <a href="https://skullpunks.com" target="_blank">
              <img src={skullpunks} alt="Project 1" />
            </a>

            <div>
              <h2>Skullpunks</h2>
              <p>A collection of 10,000 funky looking skullpunks (SOLDOUT)</p>
            </div>
            <a
              href="https://skullpunks.com"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>
          <div className="project-card">
            <a href="https://zombielabs.wtf" target="_blank">
              <img src={zombie} alt="Project 1" />
            </a>

            <div>
              <h2>Zombie Labs</h2>
              <p>
                Introducing SoRekt Meme Coin: Uniting a community through humor
                and resilience in the face of market challenges. Join us on this
                crypto journey!
              </p>
            </div>
            <a
              href="https://zombielabs.wtf"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>
          <div className="project-card">
            <a href="/mello-coming-soon" target="_blank">
              <img src={mello} alt="Project 1" />
            </a>

            <div>
              <h2>Mello</h2>
              <p>
                A collection of 7,900 Mello Fellos roaming around the metaverse
                in search of art.
              </p>
            </div>
            <a
              href="/mello-coming-soon"
              className="learn-more-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>
          <div className="project-card" style={{ opacity: 0 }}>
            <a href="/mello-coming-soon">
              <img src={mello} alt="Project 1" />
            </a>
            <div>
              <h2>Mello</h2>
              <p>
                A collection of 7,900 Mello Fellos roaming around the metaverse
                in search of art.
              </p>
            </div>
            <a
              href="/mello-coming-soon"
              className="learn-more-button"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>
          {/* Repeat the above project card structure for other projects */}
        </div>
      </section>
      <Footer helpIcon={false} />
    </div>
  );
};

export default NFTInner;
