import React from "react";
// import { baseDEXURL,baseCEXURL } from "../../../services/api";
import "./TradeToEarnBanner.css";
import { baseCEXURL } from "../../../services/api";
import { Button, useMediaQuery } from "@mui/material";
// import banner from "../../../assets/SoRekt.png"
import bitcoinLogo from "../../../assets/banners/bitcoin_logo.svg";
import bitcoinText from "../../../assets/banners/bitcoin_text.svg";

import wibsLogo from "../../../assets/banners/wibs_logo.svg";
import wibsText from "../../../assets/banners/wibsTextUpdated.svg";

import shopText from "../../../assets/homeSlider/shop_text.svg";
import shopImage from "../../../assets/homeSlider/shop-card-image.png";

import newBannerText from "../../../assets/banners/new_updated_banner_textt.svg";
import newBannerGraphic from "../../../assets/banners/new_banner_graphic.png";

import btc_text from "../../../assets/banners/btc_text.svg";
import btc_logo from "../../../assets/banners/btc_image.png";

import inexLogo from "../../../assets/banners/inex_logo.svg";
import inexText from "../../../assets/banners/inex_text.svg";

import powerLogo from "../../../assets/banners/powerpack_logo.svg";
import powerText from "../../../assets/banners/powerpack_text.svg";
import { Link } from "react-router-dom";
import HomeSlider from "./BackToCommunitySlider";
import TestimonialSlider from "./TestimonialSlider";
import TestimonialBanner from "../../../assets/homeSlider/testimonialBanner.svg";

const ClaimButton = ({ text, href }: { text: string; href: string }) => {
  return (
    <Link
      to={href}
      className="yellow_bg_btn"
      style={{ width: "283.047px", textAlign: "center" }}
    >
      {text}
    </Link>
  );
};
const TradeToEarnBanner = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <>
      <div
        style={{
          background: "black",
          padding: "50px",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            className="bitcoin_right"
            style={{
              flex: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <img src={shopText} alt="text" style={{ width: "60%" }} />
            </div>
            <a
              href="https://shop.indexx.ai"
              className="blue_btn"
              style={{ width: "283.047px", textAlign: "center" }}
            >
              Shop Now
            </a>
          </div>
          <div
            className="bitcoinIcon"
            style={{
              display: "flex",
              alignItems: "center",
              flex: "60%",
            }}
          >
            <img src={shopImage} alt="bitcoinLogo" style={{ width: "90%" }} />
          </div>
        </div>
      </div>

      <div
        style={{
          background: "#FAFAFA",
          padding: "50px 50px 0px 50px",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            className="new_banner_text"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={btc_text} style={{ width: "80%" }} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px auto 70px auto",
            }}
          >
            <a
              href="/back-to-community"
              rel="noreferrer"
              className="register-links"
            >
              Learn more{" >"}
            </a>
          </div>
          <div className="new_banner_img">
            <img src={btc_logo} style={{ width: "100%" }} />
          </div>
        </div>
      </div>

      {/* New things here */}
      <HomeSlider />
      <div style={{ width: "100%", maxWidth: "1980px", margin: "0px auto" }}>
        <img src={TestimonialBanner} alt="" style={{ width: "100%" }} />
      </div>
      <TestimonialSlider />
      <div
        style={{
          background: "#FAFAFA",
          padding: "50px 50px 0px 50px",
          margin: "50px auto 50px auto",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: !isMobile ? "72px" : "36px",
            marginBottom: 10,
            color: "black",
          }}
        >
          We hold a lot...
        </h2>
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            className="new_banner_text"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={newBannerText} style={{ width: "100%" }} />
          </div>
          <div className="new_banner_img">
            <img src={newBannerGraphic} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
      <div
        style={{
          background: "black",
          padding: "50px 50px",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",

            justifyContent: "space-around",
          }}
        >
          <div
            className="bitcoinIcon"
            style={{
              display: "flex",
              alignItems: "center",
              flex: "60%",
            }}
          >
            <img src={wibsLogo} alt="bitcoinLogo" style={{ width: "100%" }} />
          </div>
          <div
            className="bitcoin_right"
            style={{
              flex: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "30px",
              }}
            >
              <img src={wibsText} alt="text" style={{ width: "100%" }} />
            </div>
            <a
              href="https://whoisbitcoinsatoshi.wtf/coin"
              className="yellow_bg_btn"
              style={{ width: "283.047px", textAlign: "center" }}
            >
              Buy Now
            </a>
          </div>
        </div>
      </div>

      {/* <div
        style={{
          background: "black",
          padding: "50px 50px",
          margin: "150px auto 50px auto",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
          }}
        >
          <div
            className="bitcoinIcon"
            style={{
              flex: 1,
              display: "flex",

              alignItems: "center",
            }}
          >
            <img src={bitcoinLogo} alt="bitcoinLogo" style={{ width: "65%" }} />
          </div>
          <div
            className="bitcoin_right"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={bitcoinText} alt="text" style={{ width: "90%" }} />
            </div>
            <ClaimButton text="Register for airdrop" href="/airdrop-bitcoin" />
          </div>
        </div>
      </div> */}
      <div className="my-container d-flex flex-direction-column justify-content-center margin-lr-auto">
        {/* <div className="airdrop_banner" style={{ marginTop: "300px" }}>
        <div>
          <a href="/airdrop" className="airdrop-link-1">
            Register{" >"}
          </a>
        </div>
      </div> */}

        {/* <div>
        <div
          className="crypto_carnival"
          style={{
            position: "relative",
            marginTop: !isMobile ? "0px" : "50px",
            marginBottom: !isMobile ? "-50px" : 0,
            zIndex: 11,
          }}
        >
          <div
            style={{
              marginTop: !isMobile ? "-110px" : "80px",
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: !isMobile ? 100 : 5,
            }}
          >
            <div>
              <ClaimButton text="Claim" href="/discount-shop" />
            </div>
          </div>
        </div>
      </div> */}

        {/* <div
        className="bitcoin_banner_root"
        style={{
          background: "white",
          display: "flex",
          padding: "50px 50px",
          justifyContent: "space-between",
        }}
      >
        <div
          className="inex_left"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={inexText} alt="text" style={{ width: "90%" }} />
          </div>
          <ClaimButton text="Claim" href="/discount-shop" />
        </div>
        <div
          className="inexIcon"
          style={{
            flex: 1,
            display: "flex",

            alignItems: "center",
          }}
        >
          <img src={inexLogo} alt="bitcoinLogo" style={{ width: "65%" }} />
        </div>
      </div> */}

        {/* <div
        className="bitcoin_banner_root"
        style={{
          background: "#ECECEC",
          display: "flex",
          padding: "50px 50px",
          justifyContent: "space-between",
        }}
      >
        <div
          className="bitcoinIcon"
          style={{
            flex: 1,
            display: "flex",

            alignItems: "center",
          }}
        >
          <img src={powerLogo} alt="bitcoinLogo" style={{ width: "100%" }} />
        </div>
        <div
          className="bitcoin_right"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={powerText} alt="text" style={{ width: "80%" }} />
          </div>
          <ClaimButton text="Claim" href="/discount-shop" />
        </div>
      </div> */}

        {/* <a href="https://indexx.ai/airdrop-iusd" target="_blank" rel="noreferrer">
        <div
          className="ferrari_banner"
          style={{
            marginTop: !isMobile ? "120px" : "50px",
            position: "relative",
            marginBottom: !isMobile ? "-50px" : 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              width: "50%",
              bottom: !isMobile ? 50 : 5,
            }}
          >
            <br />
            <a
              href="https://indexx.ai/airdrop-iusd"
              target="_blank"
              rel="noreferrer"
              className="register-links"
            >
              Register for Airdrop{" >"}
            </a>
          </div>
        </div>
      </a> */}
        <div>
          <div
            className="get_free_token"
            style={{
              position: "relative",
              marginTop: !isMobile ? "80px" : "50px",
              marginBottom: !isMobile ? "-50px" : 0,
            }}
          >
            <div
              style={{
                marginTop: !isMobile ? "0px" : "80px",
                position: "absolute",
                width: "50%",
                left: 0,
                bottom: !isMobile ? 50 : 5,
              }}
            >
              <div>
                <a
                  href="https://lotto.indexx.ai/reward"
                  target="_blank"
                  rel="noreferrer"
                  className="register-links"
                >
                  Claim Now{" >"}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="giftcard_banner"
          style={!isMobile ? { marginTop: "10px" } : { marginTop: "50px" }}
        >
          <div>
            <a
              href="https://indexxgifts.com"
              target="_blank"
              rel="noreferrer"
              className="gift-link"
            >
              Buy Bonus Shop{" >"}
            </a>
          </div>
        </div>

        {/* <div className="greeting_banner" style={ !isMobile? { marginTop: "300px" }:{marginTop:"50px"}}>
        <div style={{ marginTop: "-300px" }}>
          <a
            href={`${baseCEXURL}/indexx-exchange/dashboard/capt-greet`}
            className="greeting-link"
            style={{ marginTop: -200 }}
          >
            Send Greeting Card
          </a>
        </div>
      </div> */}

        <div
          className="wallstreet_banner"
          style={!isMobile ? { marginTop: "300px" } : { marginTop: "50px" }}
        >
          <div>
            <a href="https://wallstreet.indexx.ai/" className="wall-link">
              Learn More{" >"}
            </a>
          </div>

          <div>
            <a href="https://wallstreet.indexx.ai/" className="wall-link">
              Buy{" >"}
            </a>
          </div>
        </div>

        <div
          className="hive_banner"
          style={!isMobile ? { marginTop: "300px" } : { marginTop: "50px" }}
        >
          <div>
            <a href="https://hive.indexx.ai/" className="hive-link">
              Learn More{" >"}
            </a>
          </div>

          <div>
            <a href="https://hive.indexx.ai/sign-up" className="hive-link">
              Join Indexx Hive{" >"}
            </a>
          </div>
        </div>

        {/* <div className="small-container">
        <div className="crypto_banner ">
          <div>
            <a href="https://indexx.ai/digital-products-info/#bitcoin">
              Learn More{" >"}
            </a>
          </div>

          <div>
            <a href="https://shop.indexx.ai/collections/gift-cards-1">
              Buy{" >"}
            </a>
          </div>
        </div>

        <div className="bitcoin_banner">
          <div>
            <a href="https://xnft.indexx.ai/">Learn More{" >"}</a>
          </div>

          <div>
            <a href="https://xnft.indexx.ai/">Buy{" >"}</a>
          </div>
        </div>
      </div> */}

        {/* <div className="small-container">
        <div className="greet_banner">
          <div>
            <a href="https://indexx.ai/digital-products-info/#greet-cards">
              Learn More{" >"}
            </a>
          </div>

          <div>
            <a href="https://shop.indexx.ai/collections/greeting-cards">
              Buy{" >"}
            </a>
          </div>
          <div></div>
          <div></div>
        </div>

        <div className="xnft_banner">
          <div>
            <a href="https://xnft.indexx.ai/">Learn More{" >"}</a>
          </div>

          <div>
            <a href="https://xnftmarketplace.indexx.ai/collections?type=all">
              Buy{" >"}
            </a>
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
};

//  <span className="text-center" style={{ textAlign: "center" }}>
// <div className="trade_right text-center justify-content-center ">
//   <h1
//     className="mega_heading text-white line_height_1  text-center "
//     style={{ textAlign: "center" }}
//   >
//     Trade to Earnn
//     <sub className="tm_text">TM</sub>
//   </h1>
//   <h1 className="mega_heading  line_height_1 center">
//     <span style={{ fontSize: 40, color: "white", fontWeight: 500 }}>
//       Upto{" "}
//     </span>
//     <span style={{ fontSize: 60, color: "greenyellow" }}>30%</span>
//   </h1>
//   <p
//     className="text-white "
//     style={{ fontSize: 40, color: "white", fontWeight: 500 }}
//   >
//     on each transaction.
//   </p>

//   <div className="actions text-center" style={{ marginTop: -40 }}>

// {/* <a href={baseDEXURL}><button  className="earnbutton" style={{width:290,height:32}}>Exchange</button></a> */}

//       {/* <a
//         href={`${baseDEXURL}/TradeToEarn`}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <span className="td-none">

//           <div className="launch-app-button-banner cut_button">
//             <div className="launch-app-button-text">Decentralized</div>
//           </div>

//         </span>
//       </a>
//       <a
//         className="td-none"
//         href={`${baseCEXURL}/indexx-exchange/trade-to-earn`}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <div className="login-button-banner cut_button">
//           <div className="login-button_text">Centralized</div>
//         </div> */}
//       {/* </a>
//       <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
//     </div>
//   </div> */}
//  {/* </span> */}

export default TradeToEarnBanner;
