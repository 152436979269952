import React from "react";
import WhalesClub from "../components/updated/WhalesClub";

const WhalesClubPage = () => {
  return (
    <div>
      <WhalesClub />
    </div>
  );
};

export default WhalesClubPage;
