import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import { makeStyles, useTheme } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "100px 20px",
  },
  heading: {
    textAlign: "center",
    fontSize: "40px",
    padding: "20px 0",
    marginBottom: "0",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  subHeading: {
    fontSize: "25px",
    paddingTop: "30px",
    paddingBottom: "20px",
    marginBottom: "0",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  listItem: {
    backgroundColor: `${theme.palette.divider}`,
    padding: "20px",
    margin: "10px 0",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  jobTitle: {
    fontSize: "25px",
    margin: "0",
  },
  jobInfo: {
    fontSize: "15px",
    color: "#666",
  },
  applyButton: {
    padding: "10px 20px",
    backgroundColor: "#ff4d4f",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    textAlign: "center",
    transition: "background-color 0.3s",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#ff7875",
    },
  },
  contactInfo: {
    paddingTop: "20px",
    textAlign: "center",
    fontSize: "20px",
  },
  contactLink: {
    color: "#ff4d4f",
    textDecoration: "none",
    paddingLeft: "5px",
  },
  "@media (max-width: 768px)": {
    listItem: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    applyButton: {
      width: "100%",
      marginTop: "10px",
    },
  },
}));

const jobData = [
  {
    title: "Compliance Data Scientist/Analyst",
    location: "U.S. REMOTE",
    category: "DATA & ANALYTICS",
  },
  {
    title: "Data Analyst (Finance)",
    location: "U.S. REMOTE",
    category: "DATA & ANALYTICS",
  },
  {
    title: "Data Scientist - Customer Support",
    location: "U.S. REMOTE",
    category: "DATA & ANALYTICS",
  },
  {
    title: "Data Scientist (Visualization)",
    location: "U.S. REMOTE",
    category: "DATA & ANALYTICS",
  },
  {
    title: "Director Performance Marketing",
    location: "U.S. REMOTE",
    category: "DATA & ANALYTICS",
  },
];

const Career: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.container}>
        <div className="border-bottom width-100">
          <h2 className={classes.heading}>Careers</h2>
        </div>

        <div className="border-bottom padding-b-3x">
          <h4 className={classes.subHeading}>DATA AND ANALYTICS</h4>

          {jobData.map((job, index) => (
            <div key={index} className={classes.listItem}>
              <div>
                <h6 className={classes.jobTitle}>{job.title}</h6>
                <span className={classes.jobInfo}>
                  {job.location} {job.category}
                </span>
              </div>

              <Link
                className="btn btn-danger width_50 apply_button"
                to="/indexx-exchange/coming-soon?page=Careers"
              >
                APPLY
              </Link>
            </div>
          ))}
        </div>

        <div className={classes.contactInfo}>
          <span>
            For inquiries, please reach us out at
            <a href="mailto:hr@indexx.ai" className={classes.contactLink}>
              {" "}
              hr@indexx.ai
            </a>
          </span>
        </div>
      </div>

      <div className="padding-t-3x margin-t-3x margin-t_300x">
        <Footer />
      </div>
    </>
  );
};

export default Career;
