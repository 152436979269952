import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import eliteClub from "../../../assets/updated/eliteCLub/Elite club.svg";
import img1 from "../../../assets/updated/eliteCLub/1.png";
import img2 from "../../../assets/updated/eliteCLub/2.svg";
import img3 from "../../../assets/updated/eliteCLub/3.svg";
import img4 from "../../../assets/updated/eliteCLub/44.svg";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    maxWidth: "1200px",
    margin: "100px auto",

    flexDirection: "column", // Default for mobile and tab
    [theme.breakpoints.up("md")]: {
      flexDirection: "row", // Row for web view
    },
    padding: "20px",
  },
  leftDiv: {
    flex: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightDiv: {
    flex: "70%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    fontSize: "38px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  primaryHeading: {
    color: theme.palette.text.primary,
    fontSize: "82px",
    fontWeight: "bold",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "52px",
    },
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  anotherContainer: {
    display: "flex",
    maxWidth: "1200px",
    margin: "0px auto 50px auto",
    flexDirection: "column", // Default for mobile and tab
    [theme.breakpoints.up("md")]: {
      flexDirection: "row", // Row for web view
      flexWrap: "wrap",
    },
    padding: "20px",
  },
  itemDiv: {
    flex: "1 1 50%", // 50% width for mobile and tab
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    [theme.breakpoints.up("md")]: {
      flex: "1 1 25%", // 25% width for web view
    },
  },
  itemImage: {
    width: "100px",
    height: "100px",
    marginBottom: "10px",
  },
  itemText: {
    color: theme.palette.text.primary,
    fontSize: "18px",
    textAlign: "center",
  },
  infoText: {
    padding: "50px 20px",
    textAlign: "center",
    maxWidth: "1200px",
    margin: "50px auto 150px auto",
    color: theme.palette.text.primary,
    fontSize: "16px",
  },
}));

const EliteClub = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <div className={classes.mainContainer}>
        <div className={classes.leftDiv}>
          <img src={eliteClub} alt="Left Image" />
        </div>
        <div className={classes.rightDiv}>
          <h3 className={classes.secondaryHeading}>indexx</h3>
          <h1 className={classes.primaryHeading}>Elite Club</h1>
          <p className={classes.text}>
            Offers exclusive investment opportunities, expert advice, and a
            network of professionals to enhance your financial growth.
          </p>
        </div>
      </div>
      <div className={classes.anotherContainer}>
        <div className={classes.itemDiv}>
          <img src={img1} alt="Item Image 1" className={classes.itemImage} />
          <p className={classes.itemText}>Fox Tail </p>
        </div>
        <div className={classes.itemDiv}>
          <img src={img2} alt="Item Image 2" className={classes.itemImage} />
          <p className={classes.itemText}>Eagle Eye</p>
        </div>
        <div className={classes.itemDiv}>
          <img src={img3} alt="Item Image 3" className={classes.itemImage} />
          <p className={classes.itemText}>Bull Run</p>
        </div>
        <div className={classes.itemDiv}>
          <img src={img4} alt="Item Image 4" className={classes.itemImage} />
          <p className={classes.itemText}>Lion Crown</p>
        </div>
      </div>

      <p className={classes.infoText}>
        For more information, please send us your email to bz@indexx.ai
      </p>
      <div style={{ margin: "50px" }}></div>
    </div>
  );
};

export default EliteClub;
