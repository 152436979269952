import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import exchange from "../../assets/arts/airdrop/exchange logo_green 8.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./RegisterAirDrop.css";
import { airdropRegister, baseCEXURL, checkemail } from "../../services/api";
import CustomSnackbar from "../CustomSnackbar";
import errorLogo from "../../assets/popup/info 7 1.png";
import successLogo from "../../assets/popup/eagle wibs 2.png";

const ExchangeRegister = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const [emailAdd, setEmailAdd] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [notificationContent, setNotificationContent] = useState(null);

  const airdropAmount = 15;
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const validateEmail = (mail) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail);
    setEmailError(isValid ? "" : "Invalid email address");
    return isValid;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({ ...notification, open: false });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await checkemail(emailAdd);
      if (res.success) {
        // setNotification({ open: true, message: 'User email is not registered', severity: 'error' });
        setNotificationContent({
          open: true,
          message: "User email is not registered",
          severity: "error",
        });
      } else {
        if (res.userType === "Indexx Exchange") {
          let register = await airdropRegister(emailAdd, res.userType, undefined, undefined, airdropAmount, "INEX");
          setNotification({ open: true, message: register.data.message + " for airdrop", severity: 'success' });
        } else {
          // setNotification({
          //   open: true,
          //   message: `Only Normal user can register here. The email provided is ${res?.userType}. Please use Hive Exchange to register for airdrop.`,
          //   severity: 'error',
          // });
          setNotificationContent({
            open: true,
            message: `Only Normal user can register here. The email provided is ${res?.userType}. Please use Hive Exchange to register for airdrop.`,
            severity: "error",
          });
        }
      }
    } catch (error) {
      console.error("Error in submitting:", error);
      // setNotification({ open: true, message: 'An error occurred during submission. Please try again later.', severity: 'error' });
      setNotificationContent({
        open: true,
        message: "An error occurred during submission. Please try again later.",
        severity: "error",
      });
    } finally {
      setIsLoading(false); // End loading
    }
  };
  const buttonArr = [
    {
      text: "Contact Us",
      close: false,
      href: "https://whoisbitcoinsatoshi.wtf",
    },
    {
      text: "Retry",
      close: true,
      href: "#",
    },
  ];

  return (
    <div style={{ paddingTop: "180px" }}>
      {/* <Snackbar
        open={notification.open}
        autoHideDuration={10000}
        onClose={handleClose}
        sx={{
          top: '50% !important',
          left: '50% !important',
          transform: 'translate(-50%, -50%) !important',
        }}
      >
        <MuiAlert
          onClose={handleClose}
          severity={notification.severity}
          sx={{
            width: '100%',
            border: '2px solid #0ea55a',
            backgroundColor: 'white',
          }}
        >
          {notification.message}
        </MuiAlert>
      </Snackbar> */}
      <CustomSnackbar
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        icon={
          notificationContent?.severity === "error" ? errorLogo : successLogo
        }
        heading={
          notificationContent?.severity === "error" ? "Oops" : "Congratulations"
        }
        paragraph={notificationContent?.message}
        buttonArr={buttonArr}
      />
      <div className="d-flex flex-column justify-content-center align-items-center font_40x text-center fw-bold mb-5">
        <img src={exchange} alt="hive" style={{ width: "fit-content" }} />
        <div className="mt-2">
          Airdrop Registration
          <div className={isMobile ? "font_50x fw-bold" : ""}>Exchange</div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center mt-2 pb-2">
        <Box
          width={isMobile ? "84%" : "590px"}
          className="d-flex flex-column align-items-center"
          mb={isMobile ? 0 : 5}
        >
          <Typography
            variant="text"
            fontSize={"15px"}
            fontWeight={500}
            textAlign={"left"}
            width={"100%"}
          >
            Email Address:
          </Typography>

          <TextField
            label=""
            placeholder="Enter your CaptainBee or HoneyBee Email Address"
            variant="outlined"
            sx={{
              width: "100%",
              border: "none", // Add this line to remove the border
            }}
            size="small"
            value={emailAdd}
            error={emailError !== ""}
            helperText={emailError}
            onChange={(e) => {
              setEmailAdd(e.target.value);
              validateEmail(e.target.value);
            }}
          />

          <Button
            variant="contained"
            disableTouchRipple
            onClick={handleSubmit}
            disabled={true}
            sx={{
              backgroundColor: "#0ea55a",
              borderRadius: "2px",

              px: 10,
              py: 1,
              textTransform: "none",
              fontSize: "13px",
              fontWeight: 700,
              boxShadow: "none",
              mt: 2,
              mb: 2,
              height: `${isMobile ? "55px" : "40px"}`,
              "&:hover": {
                backgroundColor: "#11be6a",
                boxShadow: "none",
              },
            }}
          >
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </Box>

        <div
          className="font_11x text-center"
          style={{ width: `${isMobile ? "100%" : "316px"}` }}
        >
          If you don't have an Exchange account, please
          {!isMobile ? <br /> : <>&nbsp;</>}
          <a
            href={`${baseCEXURL}/indexx-exchange/buy-sell/get-started`}
            className="exch-link"
          >
            Signup here
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExchangeRegister;
