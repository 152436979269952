import React from "react";
import Slider from "react-slick";
import img1 from "../../../assets/homeSlider/test1.svg";
import img2 from "../../../assets/homeSlider/test2.svg";
import img3 from "../../../assets/homeSlider/test3.svg";
import img4 from "../../../assets/homeSlider/test4.svg";
import img5 from "../../../assets/homeSlider/test5.svg";
import img6 from "../../../assets/homeSlider/test6.svg";
import img7 from "../../../assets/homeSlider/test7.svg";
import img8 from "../../../assets/homeSlider/test8.svg";
import img9 from "../../../assets/homeSlider/test9.svg";
import img10 from "../../../assets/homeSlider/test10.svg";
import logo from "../../../assets/homeSlider/testimonialLogo.svg";
import { makeStyles } from "@mui/styles";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"; // Import icons for arrows
import { ArrowPrevIcon } from "../../../assets/icons/ArrowPrev";
import { ArrowNextIcon } from "../../../assets/icons/ArrowNext";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2rem",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  logoContainer: {
    padding: "50px",
  },
  imgContainer: {
    width: "100%",
    padding: "15px",
    borderRadius: "10px",
    overflow: "hidden",
    "&>img": {
      width: "100%",
      borderRadius: "10px",
    },
  },
  heading: {
    fontSize: "52px",
    fontWeight: "700",
    marginBottom: "2rem",
    color: "white",
  },
  swiperContainer: {
    position: "relative", // Set position relative to enable absolute positioning of arrows
    margin: "2rem auto",
  },
  slickArrow: {
    position: "absolute",
    top: "100%", // Center the arrows vertically
    transform: "translateY(-30%)", // Adjust vertical alignment
    backgroundColor: "none",
    height: "50px",
    width: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    opacity: 1,
    zIndex: 1,
    cursor: "pointer",
  },
  slickPrev: {
    right: "80px", // Position to the left of the slider
    [theme.breakpoints.down("md")]: {
      left: "-40px",
    },
  },
  slickNext: {
    right: "30px", // Position to the right of the slider
    [theme.breakpoints.down("md")]: {
      right: "-40px",
    },
  },
  slickArrowHover: {
    "&:hover": {
      color:
        theme.palette.mode === "dark" ? "white !important" : "white !important",
      opacity: 1,
    },
  },
  slickArrowIcon: {
    color: "grey",
    fontSize: "30px",
    "&:hover": {
      color:
        theme.palette.mode === "dark" ? "white !important" : "white !important",
      opacity: 1,
    },
  },
}));

function TestimonialSlider() {
  const classes = useStyles();
  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`${classes.slickArrow} ${classes.slickPrev} ${classes.slickArrowHover}`}
      >
        <span className={classes.slickArrowIcon}>
          {" "}
          <ArrowPrevIcon width={28} height={28} />
        </span>
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        className={`${classes.slickArrow} ${classes.slickNext} ${classes.slickArrowHover}`}
      >
        <span className={classes.slickArrowIcon}>
          {" "}
          <ArrowNextIcon width={28} height={28} />
        </span>
      </div>
    );
  };
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4, // Default number of slides for large screens
    speed: 500,
    nextArrow: <SampleNextArrow />, // Use custom Next arrow
    prevArrow: <SamplePrevArrow />, // Use custom Previous arrow
    responsive: [
      {
        breakpoint: 1024, // For tablet screens and below
        settings: {
          slidesToShow: 2, // Show 2 slides on tablets
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 600, // For mobile screens and below
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div style={{ background: "black", padding: "50px 0px" }}>
      <div className={classes.logoContainer}>
        <img src={logo} alt="logo" />
        <p className={classes.heading}>Hear it from our investors.</p>
      </div>
      <div className={classes.swiperContainer}>
        <Slider {...settings}>
          <div className={classes.imgContainer}>
            <img src={img1} alt="Slide 1" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img2} alt="Slide 2" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img3} alt="Slide 3" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img4} alt="Slide 4" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img5} alt="Slide 5" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img6} alt="Slide 1" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img7} alt="Slide 2" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img8} alt="Slide 3" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img9} alt="Slide 4" />
          </div>
          <div className={classes.imgContainer}>
            <img src={img10} alt="Slide 5" />
          </div>
        </Slider>

        {/* Custom Navigation Buttons */}
      </div>
    </div>
  );
}

export default TestimonialSlider;
