import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "26px",
    cursor: "pointer", // Make the card look clickable
    transition: "all .2s linear",
    border: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      transform: "scale(1.03)",
      transition: "all .2s linear",
    },
  },
  media: {
    height: 180,
    backgroundPosition: "top",
  },
  title: (props) => ({
    overflow: "hidden",
    textOverflow: props.showEllipses ? "ellipsis" : "none",
    whiteSpace: "nowrap",
    fontSize: 30,
  }),
  description: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
    fontSize: 18,
  },
}));

const CardComponent = ({ image, title, description, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={onClick}>
      <CardMedia className={classes.media} image={image} title={title} />
      <CardContent>
        <Typography fontSize={24} fontWeight={600} className={classes.title}>
          {title}
        </Typography>
        <Typography fontSize={20} marginTop={5} className={classes.description}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
