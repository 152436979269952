import React from "react";
import EliteClub from "../components/updated/EliteClub";

const EliteClubPage = () => {
  return (
    <div>
      <EliteClub />
    </div>
  );
};

export default EliteClubPage;
