import React, { useContext } from "react";
import commingSoon from "../../../assets/meme/soRekt_light.svg";
import commingSoonDark from "../../../assets/meme/soRekt_dark.svg";
import { Theme } from "../../../utils/themeContext";
const SorektComingSoon = () => {
  const themeContest = useContext(Theme);
  const theme = themeContest?.theme ?? "dark";
  return (
    <div
      style={{
        width: "100%",
        margin: "150px auto 250px auto",
        paddingBottom: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={theme === "light" ? commingSoon : commingSoonDark}
        style={{ width: "80%" }}
      />
    </div>
  );
};

export default SorektComingSoon;
