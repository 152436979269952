import React, { useEffect, useState } from "react";
import { Image } from "antd";
import "./IndexVlog.css";
import Footer from "../Footer/Footer";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import CardGrid from "../CardComponent/CardGrid";
import camera from "../../assets/arts/camera.png";
import image1 from "../../assets/arts/vlog/4a.jpg";
import image2 from "../../assets/arts/vlog/1A.jpg";
import image3 from "../../assets/arts/vlog/2A.jpg";
import image4 from "../../assets/arts/vlog/3A.jpg";
import image5 from "../../assets/arts/vlog/5a.jpg";
import image6 from "../../assets/arts/vlog/6A.jpg";
import image7 from "../../assets/arts/vlog/7A.jpg";
import image8 from "../../assets/arts/vlog/8A.jpg";
import new1 from "../../assets/arts/vlog/new1.jpg";
import new2 from "../../assets/arts/vlog/new2.png";

const vlogs = [
  {
    id: 1,
    image_url: new1,
    title: "Indexx.ai on SEC Regulations (Part 1)",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=yMReWuPmgak&feature=youtu.be",
  },
  {
    id: 2,
    image_url: image1,
    title: "Trade to Earn Premier",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=kfbl3WqJy3E&ab_channel=IndexxAi",
  },
  {
    id: 3,
    image_url: image3,
    title: "Best crypto earning exchange",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=NLcbpA7P5-Y",
  },
  {
    id: 4,
    image_url: image6,
    title: "Phoenix Token",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=o-U3nXRap5o",
  },
  {
    id: 5,
    image_url: image5,
    title: "Widely used in Philipines",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=HGO74iStTKY",
  },
  {
    id: 6,
    image_url: new2,
    title: "Indexx.ai on SEC Regulations (Part 2)",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=MMnV7-8FbVg",
  },
  {
    id: 7,
    image_url: image2,
    title: "DEX and CEX in one",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=OavICrKzPTE&t=1s",
  },
  {
    id: 8,
    image_url: image4,
    title: "Welcoming Philipines community",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=svWXVNvgc3s",
  },
  {
    id: 9,
    image_url: image7,
    title: "Best way earning of crypto",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=NLcbpA7P5-Y",
  },
  {
    id: 10,
    image_url: image8,
    title: "World's 1st Decentralized",
    subtitle: "",
    url: "https://www.youtube.com/watch?v=7PYqDX_zJkk",
  },
];

const Vlog = () => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Simulate fetching data
    setLoading(true);
    setTimeout(() => setLoading(false), 1000); // Simulate loading time
  }, []);

  return (
    <>
      <div style={loading ? { paddingTop: "180px" } : { paddingTop: "100px" }}>
        <div
          className="vlog_container container"
          style={{ paddingBottom: 200 }}
        >
          <div className="margin-t-2x vlog_container_header">
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                maxWidth: "1150px",
                margin: "50px auto",
              }}
            >
              <div className="">
                <Image
                  src={camera}
                  className="float-left"
                  preview={false}
                  style={{ width: 80, height: 52 }}
                />
              </div>
              <div className="">
                <p
                  className="font_60x"
                  style={{ marginTop: -10, textAlign: "left" }}
                >
                  Vlog
                </p>
              </div>
            </div>
          </div>
          <div className="margin-t-2x vlog_container_main">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <CardGrid posts={vlogs} showEllipses={false} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Vlog;
