import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import IndexxSwap from "./components/IndexxSwap/IndexxSwap";
import BuySell from "./components/BuySell/BuySell";
import Help from "./components/Help/Help";
import Intro from "./components/Help/Intro";
import Team from "./components/Help/Team";
import ContactUs from "./components/Help/ContactUs";
import Tokens from "./components/Tokens/Tokens";
import Legal from "./components/Legal/Legal";
import Termsofservice from "./components/Legal/Termsofservice";
import Privacypolicy from "./components/Legal/Privacypolicy";
import TermsandConditions from "./components/Legal/Termsandconditions";
import Affiliateprogram from "./components/Legal/Affiliateprogram";
import Amlkmc from "./components/Legal/Amlkmc";
import Vlog from "./components/IndexVlog/Vlog";
import TradeToEarn from "./components/TradeToEarn/TradeToEarn";
import BuySellLogin from "./components/BuySell/BuySellLogin";
import BSWallet from "./components/BuySell/BSWallet";
import ChatBot from "./components/ChatBot/ChatBot";

// import BuySellLoggedVerfication from './components/BuySell/BuySellLoggedVerfication';
import BuySellLoggedWelcome from "./components/BuySell/BuySellLoggedWelcome";
import TradeChart from "./components/TradeChart/TradeChart";
import Markets from "./components/Markets/Markets";
import IndexBlog from "./components/IndexBlog/IndexBlog";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import HowMarket from "./components/HowItWorks/howMarket";
import HowCentralized from "./components/HowItWorks/howCentralized";
import HowHive from "./components/HowItWorks/howHive";

import HowFortune from "./components/HowItWorks/howFortuneDaily";
import HowTokens from "./components/HowItWorks/howTokens";
import HowTradeToEarn from "./components/HowItWorks/howTradeToEarn";
import HowDecentralized from "./components/HowItWorks/howDecentralized";
import BSWithdrawMain from "./components/BSDepositWithdraw/BSWithdrawMain";
import BSDepositWithdarwSelect from "./components/BSDepositWithdraw/BSDepositWithdarwSelect";
import BSWithdrawInfo from "./components/BSDepositWithdraw/BSWithdrawInfo";
import BSDWAmount from "./components/BSDepositWithdraw/BSDWAmount";
import BSDWRecorded from "./components/BSDepositWithdraw/BSDWRecorded";
import BuySellGetStartedLayout from "./components/BuySell/BuySellGetStartedLayout";
import BSDepositCryproLayout from "./components/BSDeposit/BSDepositCryproLayout";
import BSTransactionHistoryLayout from "./components/BSTransactionHistory/BSTransactionHistoryLayout";
import BSDepositFiatLayout from "./components/BSDeposit/BSDepositFiatLayout";
import BSWithdrawCryptoLayout from "./components/BSDepositWithdraw/BSWithdrawCryptoLayout";
import IndexxTokens from "./components/IndexxTokens/IndexxTokens";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import HeaderNew from "./components/Header/HeaderNew";
import About from "./components/About/About";
import IndexxCrypto from "./components/Tokens/indexCrypto";
import Index500 from "./components/Tokens/index500";
import IndexUSD from "./components/Tokens/indexUsd";
import Account from "./components/AccountSettings/Account";
// import BSDashhboard from './components/BuySell/BSDashboard';
import SecurityChange from "./components/AccountSettings/SecurityChange";
// import Blockpass from './components/Blockpass/Blockpass';
import BlockpassLink from "./components/Blockpass/BlockpassLink";
// import { useEffect, useState } from 'react';
// import { decodeJWT, getUserDetails } from '../src/services/api';

// import Header from './components/Header/Header';
// import BuySellGetStarted from './components/BuySell/BuySellGetStarted';
// import BuySellEmailAuth from './components/BuySell/BuySellEmailAuth';
// import BuySellSecureSteps from './components/BuySell/BuySellSecureSteps';
// import BuySellMobiAuth from './components/BuySell/BuySellMobiAuth';
// import BuySellmobiVerfication from './components/BuySell/BuySellmobiVerfication';
import ScrollToTop from "./utils/ScrollToTop";
import Nft from "./components/Nft/Nft";
import Notification from "./components/Notifications/Notification";
import AllNotification from "./components/Notifications/AllNotification";
import Activity from "./components/Notifications/Activity";
import SystemMsg from "./components/Notifications/SystemMsg";
import Career from "./components/Careers/Career";
import WelcomePage from "./components/WelcomePage";
import IndexxEX from "./components/Tokens/indexEx";
import IndexxPho from "./components/Tokens/indexPho";
// import CoverPage from "./components/Product-Details/CoverPage";
import MainPage from "./components/Product-Details/MainPage";
import BitcoinGC from "./components/Product-Details/BitcoinGC";
import EthereumGC from "./components/Product-Details/EthereumGC";
import BitcoinXNFT from "./components/Product-Details/BitcoinXNFT";
import EthereumXNFT from "./components/Product-Details/EthereumXNFT";
import BitcoinGreet from "./components/Product-Details/BitcoinGreet";
import EthereumGreet from "./components/Product-Details/EthereumGreet";
import AirDrop from "./components/AirDrop/AirDrop";
import RegisterAirDrop from "./components/AirDrop/RegisterAirDrop";
import ChooseAirDrop from "./components/AirDrop/ChooseAirDrop";
import HiveRegister from "./components/AirDrop/HiveRegister";
import ExchangeRegister from "./components/AirDrop/ExchangeRegister";
import WalletRegister from "./components/AirDrop/WalletRegister";
import ComingSoonNFT from "./components/ComingSoon/ComingSoonNFT";
import ComingSoonNakaMoto from "./components/ComingSoon/ComingSoonNakaMoto";
import IusdAirDrop from "./components/iusdAirDrop/AirDrop";
import BtcAirDrop from "./components/btcAirDrop/AirDrop";
import RegisterIusdAirDrop from "./components/iusdAirDrop/RegisterAirDrop";
import RegisterBtcAirDrop from "./components/btcAirDrop/RegisterAirDrop";
import IusdHiveRegister from "./components/iusdAirDrop/HiveRegister";
import IusdExchangeRegister from "./components/iusdAirDrop/ExchangeRegister";
import IusdWalletRegister from "./components/iusdAirDrop/WalletRegister";

import BtcHiveRegister from "./components/btcAirDrop/HiveRegister";
import BtcExchangeRegister from "./components/btcAirDrop/ExchangeRegister";
import BtcWalletRegister from "./components/btcAirDrop/WalletRegister";

import IusdChooseAirDrop from "./components/iusdAirDrop/ChooseAirDrop";
import SuperBallChooseAirDrop from "./components/super-ball/ChooseAirDrop";
import SuperBallAirDrop from "./components/super-ball/AirDrop";
import RegisterSuperBallAirDrop from "./components/super-ball/RegisterAirDrop";
import SuperBallHiveRegister from "./components/super-ball/HiveRegister";
import SuperBallExchangeRegister from "./components/super-ball/ExchangeRegister";
import SuperBallWalletRegister from "./components/super-ball/WalletRegister";
import DiscountShop from "./components/DiscountShop";
import HeaderTest from "./components/Header_test";
import { ThemeContext } from "./utils/themeContext.jsx";
import MemePage from "./components/Home/MemeInnerPage";
import CoinInner from "./components/Home/MemeInnerPage/CoinInner";
import OrdinalInner from "./components/Home/MemeInnerPage/OrdinalInner";
import NFTInner from "./components/Home/MemeInnerPage/NFTInner";
import WISAirdrop from "./components/WISAirdrop/AirDrop";
import WisChooseAirDrop from "./components/WISAirdrop/ChooseAirDrop";
import WisHiveRegister from "./components/WISAirdrop/HiveRegister";
import WisExchangeRegister from "./components/WISAirdrop/ExchangeRegister";
import WisWalletRegister from "./components/WISAirdrop/WalletRegister";

import RegisterWisAirDrop from "./components/WISAirdrop/RegisterAirDrop";
import MelloCommingSoon from "./components/Home/MemeInnerPage/MelloCommingSoon";
import SorektComingSoon from "./components/Home/MemeInnerPage/SorektComingSoon";
import { PopupContext } from "./utils/PopupContest";
import { useEffect, useRef } from "react";

import Login from "./pages/auth/LoginEmail/index";
import LoginPassword from "./pages/auth/LoginPassword";
import ResetPass from "./pages/auth/ResetPassword";
import SignUpEmailPage from "./pages/auth/Signup/EmailOrPhone";
import SignUpEmailVerificationPage from "./pages/auth/Signup/EmailVerification";
import { decodeJWT, loginWithToken } from "./services/api";
import CreatePasswordPage from "./pages/auth/Signup/CreatePassword";
import RefferalPage from "./pages/auth/Signup/Refferal";
import HowWorks from "./components/Works/HowWorks";
import RedirectionPopup from "./components/updated/shared/RedirectionPopup";
import LoginPopup from "./components/updated/shared/LoginPopup";
import SignupPopup from "./components/updated/shared/RegisterPopup";
import EliteClubPage from "./pages/EliteClub";
import WhalesClubPage from "./pages/WhalesClub";
import { Helmet } from "react-helmet";
import BackToCommunity from "./pages/BackToCommunity";

// import CareerSoon from './components/Careers/CareerSoon';

function App() {
  const inactiveDurationThreshold = useRef(1200000); // 1 minute in milliseconds
  const lastVisibleTime = useRef(Date.now());

  useEffect(() => {
    // Define the error handler function
    const unhandledErrorDefaultHandler = (event: any) => {
      console.log("UNHANDLED ERROR:" + event.message);
      if (event.message === "Uncaught SyntaxError: Unexpected token '<'") {
        const lastReload = localStorage.getItem("error_reload");
        if (lastReload && +lastReload + 10000 > new Date().valueOf()) {
          console.log("Error loop detected");
        } else {
          localStorage.setItem("error_reload", String(new Date().valueOf()));
          alert("Application was updated, and will be reloaded.");
          window.location.reload();
        }
      }
      return false;
    };

    // Add the error event listener
    window.addEventListener("error", unhandledErrorDefaultHandler);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("error", unhandledErrorDefaultHandler);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const currentTime = Date.now();
      if (document.visibilityState === "visible") {
        // Tab is visible, check if it was inactive for more than the threshold duration
        if (
          currentTime - lastVisibleTime.current >
          inactiveDurationThreshold.current
        ) {
          // Tab was inactive for too long, trigger a reload or refresh
          window.location.reload(); // You can also perform other actions like fetching data again
        }
        lastVisibleTime.current = currentTime;
      } else {
        lastVisibleTime.current = currentTime;
      }
    };

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Cleanup: remove event listener
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []); // Run effect only once when component mounts

  const params = new URLSearchParams(window.location.search);
  const defaultSignInToken = params.get("signInToken");

  useEffect(() => {
    const redirectFlag = localStorage.getItem("redirected");
    if (defaultSignInToken && !redirectFlag) {
      checkLogin(defaultSignInToken);
    }
  }, [defaultSignInToken]);

  async function checkLogin(defaultSignInToken: any) {
    try {
      const res = await loginWithToken(defaultSignInToken);
      if (res.status === 200) {
        let resObj: any = await decodeJWT(res.data.access_token);
        localStorage.setItem("email", resObj?.email);
        localStorage.setItem("user", resObj?.email);
        localStorage.setItem("access_token", resObj?.access_token);
        localStorage.setItem("refresh_token", resObj?.refresh_token);
        localStorage.setItem("userType", resObj?.userType);
        localStorage.setItem("redirected", "true"); // Set flag
        window.location.reload();
      } else {
        console.log(res.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <div>
      <BrowserRouter>
        <ThemeContext>
          <Helmet></Helmet>
          <PopupContext>
            <ScrollToTop />
            {/* <HeaderNew /> */}
            <HeaderTest />
            <Routes>
              {/* Below line should be enabled for prod */}
              {/* <Route path="/" element={<Home />} />  */}
              {/* Below line should be enabled for test */}
              {/* 
        {(localStorage.getItem("access_token") === undefined || localStorage.getItem("access_token") === null) ?
            <Route path="/*" element={<BuySellLogin />} />
            :
            <Route path="/*" element={<Home />} />
          } */}

              <Route path="/*" element={<Home />} />
              <Route path="/meme-details" element={<MemePage />} />
              <Route path="/back-to-community" element={<BackToCommunity />} />
              <Route path="/elite-club" element={<EliteClubPage />} />
              <Route path="/whales-club" element={<WhalesClubPage />} />

              <Route path="/mello-coming-soon" element={<MelloCommingSoon />} />
              <Route
                path="/sorekt-coming-soon"
                element={<SorektComingSoon />}
              />
              <Route path="/meme-details/meme-coin" element={<CoinInner />} />
              <Route path="/meme-details/nft" element={<NFTInner />} />
              <Route
                path="/meme-details/ordinal-coin"
                element={<OrdinalInner />}
              />
              <Route path="/discount-shop" element={<DiscountShop />} />
              <Route path="/indexx-exchange/dashboard" element={<Account />} />
              {/* {
                        // userData?.role === "Standard"
                            ? <Route path="/indexx-exchange/dashboard" element={<BSDashhboard />} />
                            : <Route path="/external-link" element={<External />} />
                    } */}

              <Route path="/indexx-exchange/kyc" element={<BlockpassLink />} />
              <Route path="/indexx-exchange/swap" element={<IndexxSwap />} />
              <Route
                path="/indexx-exchange/coming-soon"
                element={<ComingSoon />}
              />
              <Route
                path="/indexx-exchange/coming-soon-nfts"
                element={<ComingSoonNFT />}
              />
              <Route
                path="/indexx-exchange/coming-soon-nak"
                element={<ComingSoonNakaMoto />}
              />
              <Route
                path="/indexx-exchange/tokens"
                element={<IndexxTokens />}
              />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/login-password" element={<LoginPassword />} />
              <Route path="/auth/reset-password" element={<ResetPass />} />
              <Route path="/auth/signup-email" element={<SignUpEmailPage />} />
              <Route
                path="/auth/signup-email-verification"
                element={<SignUpEmailVerificationPage />}
              />
              <Route
                path="/auth/signup-create-password"
                element={<CreatePasswordPage />}
              />
              <Route path="/auth/signup-referral" element={<RefferalPage />} />
              <Route path="/indexx-exchange/buy-sell/*" element={<BuySell />} />
              <Route path="/airdrop" element={<AirDrop />} />
              <Route path="/airdrop-iusd" element={<IusdAirDrop />} />
              <Route path="/airdrop-bitcoin" element={<BtcAirDrop />} />
              <Route path="/sale-wibs" element={<WISAirdrop />} />
              <Route path="/airdrop-wis" element={<WISAirdrop />} />
              <Route path="/airdrop-superball" element={<SuperBallAirDrop />} />
              <Route path="/airdrop-register" element={<RegisterAirDrop />} />
              <Route
                path="/airdrop-register-iusd"
                element={<RegisterIusdAirDrop />}
              />
              <Route
                path="/airdrop-register-wis"
                element={<RegisterWisAirDrop />}
              />
              <Route
                path="/airdrop-register-bitcoin"
                element={<RegisterBtcAirDrop />}
              />
              <Route
                path="/airdrop-register-superball"
                element={<RegisterSuperBallAirDrop />}
              />
              <Route path="/airdrop-register-hive" element={<HiveRegister />} />
              <Route
                path="/airdrop-register-iusd-hive"
                element={<IusdHiveRegister />}
              />
              <Route
                path="/airdrop-register-btc-hive"
                element={<BtcHiveRegister />}
              />

              <Route
                path="/airdrop-register-wis-hive"
                element={<WisHiveRegister />}
              />

              <Route
                path="/airdrop-register-superball-hive"
                element={<SuperBallHiveRegister />}
              />
              <Route
                path="/airdrop-register-exch"
                element={<ExchangeRegister />}
              />
              <Route
                path="/airdrop-register-wall"
                element={<WalletRegister />}
              />
              <Route
                path="/airdrop-register-iusd-exch"
                element={<IusdExchangeRegister />}
              />

              <Route
                path="/airdrop-register-wis-exch"
                element={<WisExchangeRegister />}
              />

              <Route
                path="/airdrop-register-btc-exch"
                element={<BtcExchangeRegister />}
              />
              <Route
                path="/airdrop-register-superball-exch"
                element={<SuperBallExchangeRegister />}
              />
              <Route
                path="/airdrop-register-iusd-wall"
                element={<IusdWalletRegister />}
              />
              <Route
                path="/airdrop-register-btc-wall"
                element={<BtcWalletRegister />}
              />

              <Route
                path="/airdrop-register-wis-wall"
                element={<WisWalletRegister />}
              />

              <Route
                path="/airdrop-register-superball-wall"
                element={<SuperBallWalletRegister />}
              />
              <Route
                path="/airdrop-register-mask"
                element={<ChooseAirDrop />}
              />

              <Route
                path="/airdrop-register-iusd-mask"
                element={<IusdChooseAirDrop />}
              />

              <Route
                path="/airdrop-register-wis-mask"
                element={<WisChooseAirDrop />}
              />

              <Route
                path="/airdrop-register-superball-mask"
                element={<SuperBallChooseAirDrop />}
              />
              <Route
                path="/indexx-exchange/buy-sell/login/*"
                element={<BuySellLogin />}
              />
              {/* <Route
          path="/"
          element={<Home />}
        />
         {(localStorage.getItem("access_token") === undefined || localStorage.getItem("access_token") === null) ?
            <Route path="/*" element={<BuySellLogin />} />
            :
            <Route
            path="/"
            element={<Home />}
          />
          } */}

              <Route
                path="/indexx-exchange/buy-sell/wallet"
                element={<BSWallet />}
              />
              <Route
                path="/indexx-exchange/buy-sell/withdraw-crypto/*"
                element={<BSWithdrawCryptoLayout />}
              />
              <Route
                path="/indexx-exchange/buy-sell/withdraw"
                element={<BSWithdrawMain />}
              >
                <Route index element={<BSDepositWithdarwSelect />} />
                <Route path="info" element={<BSWithdrawInfo />} />
                <Route path="amount" element={<BSDWAmount />} />
                <Route path="recorded" element={<BSDWRecorded />} />
              </Route>
              <Route
                path="/indexx-exchange/buy-sell/deposit-crypto/*"
                element={<BSDepositCryproLayout />}
              />
              <Route
                path="/indexx-exchange/buy-sell/deposit-fiat/*"
                element={<BSDepositFiatLayout />}
              />
              <Route
                path="/indexx-exchange/buy-sell/transaction-history/*"
                element={<BSTransactionHistoryLayout />}
              />
              <Route
                path="/indexx-exchange/buy-sell/get-started/*"
                element={<BuySellGetStartedLayout />}
              />
              <Route
                path="/indexx-exchange/buy-sell/welcome"
                element={<BuySellLoggedWelcome />}
              />
              <Route
                path="/indexx-exchange/trade-to-earn"
                element={<TradeToEarn />}
              />
              <Route
                path="/indexx-exchange/how-it-works"
                element={<HowItWorks />}
              />
              <Route
                path="/indexx-exchange/how-it-works/market"
                element={<HowMarket />}
              />
              <Route
                path="/indexx-exchange/how-it-works/centralized"
                element={<HowCentralized />}
              />
              <Route path="/how-master-login-works" element={<HowWorks />} />

              <Route
                path="/indexx-exchange/how-it-works/hive"
                element={<HowHive />}
              />
              <Route
                path="/indexx-exchange/how-it-works/decentralized"
                element={<HowDecentralized />}
              />
              <Route
                path="/indexx-exchange/how-it-works/fortune"
                element={<HowFortune />}
              />
              <Route
                path="/indexx-exchange/how-it-works/tradetoearn"
                element={<HowTradeToEarn />}
              />
              <Route
                path="/indexx-exchange/how-it-works/tokens"
                element={<HowTokens />}
              />
              <Route path="/indexx-exchange/charts" element={<Home />} />
              <Route path="/indexx-exchange/charts" element={<Home />} />
              {localStorage.getItem("user") ? (
                <Route path="/indexx-exchange/markets" element={<Markets />} />
              ) : (
                <Route path="/indexx-exchange/markets" element={<Markets />} />
              )}
              <Route path="/indexx-exchange/trade" element={<TradeChart />} />
              <Route
                path="/indexx-exchange/token-details"
                element={<Tokens />}
              />
              <Route
                path="/indexx-exchange/token-details/crypto"
                element={<IndexxCrypto />}
              />
              <Route
                path="/indexx-exchange/token-details/in500"
                element={<Index500 />}
              />
              <Route
                path="/indexx-exchange/token-details/usd"
                element={<IndexUSD />}
              />
              <Route
                path="/indexx-exchange/token-details/inex"
                element={<IndexxEX />}
              />
              <Route
                path="/indexx-exchange/token-details/phoenix"
                element={<IndexxPho />}
              />
              <Route path="/indexx-exchange/chatbot" element={<ChatBot />} />
              <Route path="/indexx-exchange/about" element={<About />} />
              <Route path="/indexx-exchange/nfts" element={<Nft />} />
              <Route path="/indexx-exchange/help" element={<Help />}>
                <Route index element={<Intro />} />
                <Route path="team" element={<Team />} />
                <Route path="contact" element={<ContactUs />} />
              </Route>

              <Route path="/indexx-exchange/legal" element={<Legal />}>
                <Route index element={<Termsofservice />} />
                <Route path="privacypolicy" element={<Privacypolicy />} />
                <Route
                  path="termsandconditions"
                  element={<TermsandConditions />}
                />
                <Route path="affiliateprogram" element={<Affiliateprogram />} />
                <Route path="amlkmc" element={<Amlkmc></Amlkmc>} />
              </Route>

              <Route
                path="/indexx-exchange/notification"
                element={<Notification />}
              >
                <Route index element={<AllNotification />} />

                <Route path="Activities" element={<Activity />} />
                <Route path="system" element={<SystemMsg />} />
              </Route>
              <Route path="/indexx-exchange/careers" element={<Career />} />
              <Route path="/indexx-exchange/blog" element={<IndexBlog />} />
              <Route path="/indexx-exchange/vlog" element={<Vlog />} />
              <Route
                path="/indexx-exchange/welcome"
                element={<WelcomePage />}
              />
              <Route path="/indexx-exchange/account" element={<Account />} />
              <Route
                path="/indexx-exchange/change-password"
                element={<SecurityChange />}
              />
              <Route path="digital-products-info" element={<MainPage />} />
              {/* <Route path="/digitalproducts" element={<CoverPage/>}/> */}
              <Route
                path="/digital-products-info/bitcoingift/:id"
                element={<BitcoinGC />}
              />
              <Route
                path="/digital-products-info/ethgift/:id"
                element={<EthereumGC />}
              />
              <Route
                path="/digital-products-info/bitxnft/:id"
                element={<BitcoinXNFT />}
              />
              <Route
                path="/digital-products-info/ethxnft/:id"
                element={<EthereumXNFT />}
              />
              <Route
                path="/digital-products-info/bitgreet/:id"
                element={<BitcoinGreet />}
              />
              <Route
                path="/digital-products-info/ethgreet/:id"
                element={<EthereumGreet />}
              />
            </Routes>
          </PopupContext>
        </ThemeContext>
        {/* <Header /> */}
      </BrowserRouter>
      {/* <RedirectionPopup onClose={() => console.log("onClose triger")} /> */}
      {/* <LoginPopup onClose={() => console.log("ok")} /> */}
      {/* <SignupPopup onClose={() => console.log("ok")} /> */}
    </div>
  );
}

// function External() {
//     console.log(' IAM HERE')
//     window.location.href = 'http://localhost:3002/';
//     return null;
// }

export default App;
