import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import InputField from "../../shared/TextField";
import GenericButton from "../../shared/Button";
import { useTheme } from "@mui/material/styles";
import lightModeLogo from "../../../../assets/header-icons/indexx grey.svg";
import darkModeLogo from "../../../../assets/header-icons/new indexx.ai logo.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import {
  baseNewCEXURL,
  baseAcademyUrl,
  checkByemail,
  decodeJWT,
  getUserDetails,
  getUserShortToken,
  loginAPI,
  loginHive,
  sendForgotPasswordOtp,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginPopup from "../../shared/LoginPopup";
import RedirectionPopup from "../../shared/RedirectionPopup";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("myTotallySecretKey");
const useStyles = makeStyles((theme) => ({
  Container: {
    border: `1px solid ${theme?.palette?.divider}`,
    borderRadius: "24px",
    minHeight: "580px",
    padding: "40px",
    maxWidth: "425px",
  },
  socialButton: {
    background: "none !important",
    border: `1px solid ${theme?.palette?.divider} !important`,
    margin: "10px 0px !important",
    color: `${theme?.palette?.text?.primary} !important`,
  },
  loginText: {
    fontSize: "32px",
    fontWeight: 600,
    color: `${theme?.palette?.text?.primary} !important`,
    marginBottom: "30px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    gap: "15px",
  },
  logoText: {
    color: `${theme?.palette?.text?.primary} !important`,
    fontSize: "18px",
    fontWeight: 600,
    margin: 0,
  },
  createLink: {
    color: `${theme?.palette?.primary?.main} !important`,
    background: `${theme?.palette?.background?.default} !important`,
  },
  errorText: {
    color: theme?.palette?.error?.main,
    marginTop: "8px",
  },
}));

const LoginPassword = ({ email, redirectWebsiteLink }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [loadings, setLoadings] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showRedirectionPopup, setShowRedirectionPopup] = useState(false);
  const [shortToken, setShortToken] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  console.log("redirectWebsiteLink", redirectWebsiteLink);
  const handleEmailCheck = async (values) => {
    setLoadings(true);
    const res = await checkByemail(email);

    if (res.userType === "HoneyBee" || res.userType === "Indexx Exchange") {
      let res1 = await loginAPI(email, values.password);

      if (res1.status === 200) {
        let resObj = await decodeJWT(res1.data.access_token);

        localStorage.setItem("user", resObj?.email);
        const userKey = cryptr.encrypt(password);
        localStorage.setItem("userkey", userKey);
        localStorage.setItem("userpass", password);
        localStorage.setItem("access_token", res1.data.access_token);
        localStorage.setItem("refresh_token", res1.data.refresh_token);
        localStorage.setItem("userType", resObj?.userType);
        localStorage.setItem("username", resObj?.username);
        localStorage.setItem(
          "userlogged",
          resObj?.userType === "Indexx Exchange"
            ? "normal"
            : resObj?.userType === "CaptainBee"
            ? "captain"
            : "honeyb"
        );
        let shortToken = await getUserShortToken(email);
        setShortToken(shortToken?.data);
        //let userDetails = await getUserDetails(resObj?.email);
        // Handle academy URL case
        if (redirectWebsiteLink && redirectWebsiteLink.includes("academy")) {
          const shortToken = await getUserShortToken(email);
          const urlObj = new URL(redirectWebsiteLink);
          urlObj.searchParams.set("signInToken", shortToken?.data);
          setShowRedirectionPopup(true);
          setTimeout(() => {
            window.location.href = urlObj.toString();
          }, 10000);
          return;
        }
        setShowLoginPopup(true);

        setTimeout(async () => {
          setShowLoginPopup(false);
          setLoadings(false);
          // Generate shortToken and append to redirectWebsiteLink
          if (redirectWebsiteLink) {
            const shortToken = await getUserShortToken(email);
            const urlObj = new URL(redirectWebsiteLink);
            urlObj.searchParams.set("signInToken", shortToken?.data);

            window.location.href = urlObj.toString();
          } else {
            navigate("/"); // navigate to home if redirectWebsiteLink is not provided
            setTimeout(() => {
              window.location.reload();
            }, 5000); // Add a slight delay to ensure navigate completes
          }
        }, 10000); // hide the popup after 10 seconds
      } else {
        setLoadings(false);
        setErrorMessage(
          "Incorrect password. Please retry or click 'Forgot Password?' to reset."
        );
      }
    } else if (res.userType === "CaptainBee") {
      let res2 = await loginHive(email, values.password);
      console.log("res", res2);

      if (res2.status === 200) {
        let resObj = await decodeJWT(res2.data.access_token);
        localStorage.setItem("userpass", password);
        localStorage.setItem("user", resObj?.email);
        const userKey = cryptr.encrypt(password);
        localStorage.setItem("userkey", userKey);
        localStorage.setItem("access_token", res2.data.access_token);
        localStorage.setItem("refresh_token", res2.data.refresh_token);
        localStorage.setItem("userType", resObj?.userType);
        localStorage.setItem("username", resObj?.username);
        localStorage.setItem(
          "userlogged",
          resObj?.userType === "Indexx Exchange"
            ? "normal"
            : resObj?.userType === "CaptainBee"
            ? "captain"
            : "honeyb"
        );
        let shortToken = await getUserShortToken(email);
        setShortToken(shortToken?.data);
        // Handle academy URL case
        if (redirectWebsiteLink && redirectWebsiteLink.includes("academy")) {
          const shortToken = await getUserShortToken(email);
          const urlObj = new URL(redirectWebsiteLink);
          urlObj.searchParams.set("signInToken", shortToken?.data);
          setShowRedirectionPopup(true);
          setTimeout(() => {
            window.location.href = urlObj.toString();
          }, 10000);
          return;
        }
        setShowLoginPopup(true);

        setTimeout(async () => {
          setShowLoginPopup(false);
          setLoadings(false);
          // Generate shortToken and append to redirectWebsiteLink
          if (redirectWebsiteLink) {
            const shortToken = await getUserShortToken(email);
            const urlObj = new URL(redirectWebsiteLink);
            urlObj.searchParams.set("signInToken", shortToken?.data);
            window.location.href = urlObj.toString();
          } else {
            navigate("/"); // navigate to home if redirectWebsiteLink is not provided
            setTimeout(() => {
              window.location.reload();
            }, 5000); // Add a slight delay to ensure navigate completes
          }
        }, 10000); // hide the popup after 10 seconds
      } else {
        setLoadings(false);
        setErrorMessage(
          "Incorrect password. Please retry or click 'Forgot Password?' to reset."
        );
      }
    }
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleEmailCheck(values);
    },
  });

  const resetPasswordPage = async () => {
    const res = await sendForgotPasswordOtp(email);
    if (res.status === 200) {
      navigate("/auth/reset-password", {
        state: { email: email },
      });
    }
  };

  const handleExploreIndexx = () => {
    setShowLoginPopup(false);
    navigate("/");
  };

  const handleBuyToken = () => {
    setShowLoginPopup(false);
    window.location.href = `${baseNewCEXURL}?buyToken=INEX&signInToken=${shortToken}`;
  };

  const handleAcademyRedirect = () => {
    const urlObj = new URL(redirectWebsiteLink);
    urlObj.searchParams.set("signInToken", shortToken);
    window.location.href = urlObj.toString();
    console.log("urlObj.toString();", urlObj.toString());
  };

  return (
    <div className={classes.Container}>
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          onExploreIndexx={handleExploreIndexx}
          onBuyToken={handleBuyToken}
        />
      )}
      {showRedirectionPopup && (
        <RedirectionPopup
          name="academy"
          onClose={() => setShowRedirectionPopup(false)}
          onRedirect={handleAcademyRedirect}
        />
      )}
      <div className={classes.logoContainer}>
        <img
          src={theme?.palette?.mode === "light" ? lightModeLogo : darkModeLogo}
          style={{ width: "120px" }}
        />
      </div>

      <h3 className={classes.loginText}>Enter your password</h3>
      <div style={{ margin: "15px auto" }}>
        <InputField
          label={"Password"}
          value={password}
          onChange={handlePasswordChange}
          type={showPassword ? "text" : "password"}
          {...formik.getFieldProps("password")}
          error={formik.touched.password && formik.errors.password}
          helperText={formik.errors.password}
          endAdornment={
            <InputAdornment position="end">
              <span
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </span>
            </InputAdornment>
          }
        />
        {errorMessage && <p className={classes.errorText}>{errorMessage}</p>}
      </div>

      <GenericButton
        text={"Next"}
        onClick={formik.handleSubmit}
        loading={loadings}
      />

      <div style={{ margin: "10px auto" }}></div>

      <GenericButton
        text={"Forgot password?"}
        className={classes.createLink}
        onClick={resetPasswordPage}
      />
    </div>
  );
};

export default LoginPassword;
